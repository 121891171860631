import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.regexp.split.js";
export default defineComponent({
  name: 'ImpactManagement',
  data: function data() {
    return {
      headers: [{
        text: 'Direct/indirect impact',
        value: 'directIndirectImpact'
      }, {
        text: 'Sustainability focus',
        value: 'sustainabilityFocus'
      }, {
        text: 'Measurability of impact',
        value: 'measurabilityOfImpact'
      }]
    };
  },
  computed: {
    assessment: function assessment() {
      if (this.$store.getters['assessment/getAssessment'] !== undefined) {
        return this.$store.getters['assessment/getAssessment'];
      } else {
        return undefined;
      }
    }
  },
  methods: {
    splitMarkdownItems: function splitMarkdownItems(text) {
      if (text !== undefined) {
        return text.split(/^-\s/m).slice(1);
      } else {
        return [];
      }
    }
  }
});